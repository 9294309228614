window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

require('./jquery.maskMoney.min');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });


let systemKeys = ['Tab', 'Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp', 'Delete', 'Shift'];

$(document).on('keydown', '.js-code-digit', function (e) {
    if(systemKeys.indexOf(e.key) === -1 && $(this).val().length == 1 ) {
        return false;
    }
});
$(document).on('keyup', '.js-code-digit', function (e) {
    if(systemKeys.indexOf(e.key) === -1 && $(this).val().length == 1 ) {
        $(this).next().focus();
    }
});
$(document).on('click', '#js-open-sidebar', function () {
    $('#sidebar').removeClass('hidden');
});
$(document).on('click', '#js-close-sidebar', function () {
    $('#sidebar').addClass('hidden');
});

$(document).on('click', '.js-delete-user', function () {
    $('.js-delete-user-modal').removeClass('hidden');
    $('.js-delete-user-modal form').attr('action', $('.js-delete-user-modal form').data('action').replace('#', $(this).data('id')));
});

$(document).on('click', '.js-delete-applicant', function () {
    $('.js-delete-applicant-modal').removeClass('hidden');
    $('.js-delete-applicant-modal form').attr('action', $('.js-delete-applicant-modal form').data('action').replace('#', $(this).data('id')));
});


var $inputs = $(".js-code-digit");
var intRegex = /^\d+$/;

// Prevents user from manually entering non-digits.
$inputs.on("input.js-code-digit", function(){
    if(!intRegex.test($(this).val())){
        $(this).val("");
    }
});

$('form').on('submit', function() {
    let submitBtn = $(this).find('button[type=submit]');
    if(!submitBtn.find('.animate-spin').length) {
        let html = $(submitBtn).html();
        let spinner = '<svg class="animate-spin h-4 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white; margin: 3px 10px;" viewBox="0 0 24 24"></svg>';
        $(submitBtn).html(spinner + html);
    }
});

$('.has-loader').on('click', function () {
    let submitBtn = $(this);
    if(!submitBtn.find('.animate-spin').length) {
        let html = $(submitBtn).html();
        let spinner = '<svg class="animate-spin h-4 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white; margin: 3px 10px;" viewBox="0 0 24 24"></svg>';
        $(submitBtn).html(spinner + html);
    }
})


$inputs.on("paste", function() {
    var $this = $(this);
    var originalValue = $this.val();

    $this.val("");

    $this.one("input.fromPaste", function(){
        $currentInputBox = $(this);

        var pastedValue = $currentInputBox.val();

        if (pastedValue.length == 6 && intRegex.test(pastedValue)) {
            pasteValues(pastedValue);
        }
        else {
            $this.val(originalValue);
        }

        $inputs.attr("maxlength", 1);
    });

    $inputs.attr("maxlength", 6);
});


// Parses the individual digits into the individual boxes.
function pasteValues(element) {
    var values = element.split("");

    $(values).each(function(index) {
        var $inputBox = $('.js-code-digit').eq(index);
        $inputBox.val(values[index])
    });

    $('button[type=submit]').focus();
};

$('.js-tab-link').on('click', function () {
    $('.tab-container').addClass('hidden');
    $('#' + $(this).data('target')).removeClass('hidden');

    $('.js-tab-link').prop('class', 'js-tab-link border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm');
    $(this).prop('class', 'js-tab-link border-blue-400 text-blue-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm');

    $('#tabs options').prop('selected', false);
    $('#tabs option[value="'+$(this).data('target')+'"]').prop('selected', true);
    return false;
});

$('#tabs').on('change', function () {
    $('.tab-container').addClass('hidden');
    $('#' + $(this).val()).removeClass('hidden');

    $('.js-tab-link').prop('class', 'js-tab-link border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm');
    $('.js-tab-link[data-target="'+$(this).val()+'"]').prop('class', 'js-tab-link border-blue-400 text-blue-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm');
});

$('form.regular-form').on('submit', function () {
    $(this).find('button[type=submit]')
        .prop('disabled', true);
});


const applicant2FieldsContainer = $('.applicant2_fields_container');
if(applicant2FieldsContainer.length) {
    const applicationType = $('#type').val();

    if(applicationType === 'single') {
        applicant2FieldsContainer.find('input').prop('disabled', true);
    } else {
        applicant2FieldsContainer.find('input').prop('disabled', false);
    }

    $('#type').on('change', function () {
        if($(this).val() == 'single') {
            applicant2FieldsContainer.addClass('hidden');
            applicant2FieldsContainer.find('input').prop('disabled', true);
        } else {
            applicant2FieldsContainer.removeClass('hidden');
            applicant2FieldsContainer.find('input').prop('disabled', false);
        }
    })
}

$(document).on('keydown', '.js-company-phone-input', function(e) {
    if((e.key == 'r' || e.key == 'R') && e.ctrlKey) {
        return true;
    }

    const allowedKeys = ['1','2','3','4','5','6','7','8','9','0','(',')','+','Backspace','Delete','ArrowLeft','ArrowRight','Home','End','F5','Enter'];
    if(allowedKeys.indexOf(e.key) < 0) {
        return false;
    }



    return true;
});

$("#loan_value").maskMoney({
    prefix: '$',
    precision: 0
}).maskMoney('mask');
